import React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"

const NotFoundPage = () => (
  <>
    <Helmet bodyAttributes={{ class: "info short" }} />
    <Layout>
      <Container className="info-contain">
        <Row>
          <Col>
            <h1 className="page-title">Not Found</h1>
            <p className="description">
              You just hit a route that doesn&#39;t exist.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </>
)

export default NotFoundPage
